import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar, Table, Tag, Typography,
} from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { selectors as appSelectors } from '../../App/slice';
import UserAvatar from '../../Common/UserAvatar';

const refOptions = (references, dataIndex, category) => ({
  render: (value) => references.find((ref) => ref.id === value)?.value,
  filters: references.filter((ref) => ref.category === category)
    .map((ref) => ({ text: ref.value, value: ref.id })),
  sorter: true,
});

const SessionsTable = (props) => {
  const {
    sessions, tags, loading, pagination, onIdClick, onRowSelectionChange, onTableChange,
    additionalColumns,
  } = props;

  const references = useSelector(appSelectors.makeSelectReferences());

  return (
    <Table
      sortDirections={['ascend', 'descend']}
      dataSource={sessions}
      size="small"
      columns={[
        {
          dataIndex: 'name',
          title: 'Name',
          render: (value, record) => (
            <Typography.Link onClick={() => { onIdClick(record.id); }}>
              {value}
            </Typography.Link>
          ),
        },
        {
          dataIndex: 'startTime',
          title: 'Start Time',
          render: (value) => moment(value).utc().format('lll'),
          sorter: true,
        },
        {
          dataIndex: 'duration',
          title: 'Duration (HH:mm:ss)',
          render: (value) => {
            const duration = moment.duration(moment(value), 'ms');
            return `${moment.utc(duration.asMilliseconds()).format('HH:mm:ss')}`;
            // moment.duration(value, 's').humanize(),
          },
          sorter: true,
        },
        ...additionalColumns.map((e) => ({
          dataIndex: e.category ? e.value : ['overview', e.value],
          title: (e.category || !e.unit) ? e.label : `${e.label} (${e.unit})`,
          render: (val) => {
            if (val) {
              let value = val;
              if (e.div) {
                value /= e.div;
              }
              return value.toFixed(2);
            }
            return '--';
          },
          ...(e.category ? refOptions(references, e.value, e.category) : {}),
        })),
        {
          dataIndex: ['meta', 'tags'],
          title: 'Tags',
          render: (value) => value?.map((v) => <Tag key={v}>{v}</Tag>),
          filters: tags.map((tag) => ({ text: tag.name, value: tag.name })),
          // onFilter: (value, record) => record.meta.tags.includes(value),
        },
        {
          dataIndex: ['owner', 'id'],
          title: 'Owner',
          render: (value, record) => (
            <UserAvatar
              photoUrl={record.owner.photoUrl}
              name={record.owner.name}
              email={record.owner.email}
              tooltip
            />
          ),
        },
        {
          dataIndex: 'sharedWith',
          title: 'Shared with',
          render: (value, record) => (
            <Avatar.Group maxCount={2}>
              {value.filter((e) => e.id !== record.owner.id).map((e) => (
                <UserAvatar
                  key={e.id}
                  photoUrl={e.photoUrl}
                  name={e.name}
                  email={e.email}
                  tooltip
                  onClick={() => {
                    console.log(e.id);
                  }}
                />
              ))}
            </Avatar.Group>
          ),
        },
      ]}
      rowKey="id"
      rowSelection={{
        type: 'checkbox',
        onChange: onRowSelectionChange,
        getCheckboxProps: (record) => ({ name: record.name }),
      }}
      loading={loading}
      pagination={pagination}
      onChange={onTableChange}
      scroll={{ x: 1200 }}
    />
  );
};

SessionsTable.defaultProps = {
  additionalColumns: [],
};

SessionsTable.propTypes = {
  sessions: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.object.isRequired,
  onIdClick: PropTypes.func.isRequired,
  onRowSelectionChange: PropTypes.func.isRequired,
  onTableChange: PropTypes.func.isRequired,
  additionalColumns: PropTypes.arrayOf(PropTypes.object.isRequired),
};

export default SessionsTable;
