import React from 'react';
import { Helmet } from 'react-helmet';

const SettingsPage = () => (
  <div>
    <Helmet>
      <title>Settings</title>
      <meta name="description" content="Description of SettingsPage" />
    </Helmet>
    <h3>Settings</h3>
  </div>
);

SettingsPage.propTypes = {

};

export default SettingsPage;
