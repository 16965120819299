import React from 'react';
// import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const DashboardPage = () => (
  <>
    <Helmet>
      <title>Dashboard</title>
      <meta name="description" content="Dashboard" />
    </Helmet>
    <h3>Dashboard</h3>
  </>
);

DashboardPage.propTypes = {

};

export default DashboardPage;
