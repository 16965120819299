import React from 'react';
import { Row, Space, Spin } from 'antd';

import Logo from '../NavBar/logo.png';
import { SECONDARY_COLOR } from '../../../constants';

const Splash = () => (
  <Row justify="center" align="middle" style={{ height: '100vh', background: SECONDARY_COLOR }}>
    <Space direction="vertical" size="large">
      <img src={Logo} alt="BreathIQ logo" />
      <Spin size="large" />
    </Space>
  </Row>
);
export default Splash;
