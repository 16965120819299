/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Redirect, useHistory } from 'react-router-dom';
import {
  Card, Col, Row, Space, Spin, Typography,
} from 'antd';

import EmailSignInForm from './EmailSignInForm';
import GoogleSignInButton from './GoogleSignInButton';
import { AuthContext } from '../AuthContextProvider';
import { selectors as appSelectors } from '../../App/slice';
import { SECONDARY_COLOR } from '../../../constants';

const LoginPage = () => {
  const user = useSelector(appSelectors.makeSelectUser());
  const loading = useSelector(appSelectors.makeSelectLoading());
  const { currentUser } = React.useContext(AuthContext);
  const history = useHistory();
  const [submitting, setSubmitting] = React.useState(false);

  const onSignUpClickHandler = () => {
    history.push('/register');
  };

  if (loading) {
    return (
      <Helmet>
        <title>Login</title>
        <meta name="description" content="Login" />
      </Helmet>
    );
  }

  if (currentUser && !user) {
    return <Redirect to="/onboard" />;
  }

  if (currentUser && user) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Helmet>
        <title>Login</title>
        <meta name="description" content="Login" />
      </Helmet>
      <Row justify="center" align="middle" style={{ height: '100vh', background: SECONDARY_COLOR }}>
        <Col xxl={5} xl={6} lg={8} md={12} sm={12} xs={20}>
          <Spin spinning={submitting}>
            <Card>
              <Typography>
                <Typography.Title>Sign In</Typography.Title>
              </Typography>
              <EmailSignInForm
                onSubmit={() => { setSubmitting(true); }}
                onSubmitComplete={() => { setSubmitting(false); }}
              />
              <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <GoogleSignInButton />
                <Typography style={{ textAlign: 'center' }}>
                  <Typography.Text>
                    {"Don't have an account? "}
                  </Typography.Text>
                  <Typography.Link onClick={onSignUpClickHandler}>Sign up</Typography.Link>
                </Typography>
              </Space>
            </Card>
          </Spin>
        </Col>
      </Row>
    </>
  );
};

LoginPage.propTypes = {};

export default LoginPage;
