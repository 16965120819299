import React from 'react';
import PropTypes from 'prop-types';
import {
  Badge, Button, Dropdown, Menu, Modal, Radio,
} from 'antd';
import {
  DeleteOutlined, DownOutlined, EditOutlined, ExclamationCircleOutlined, PlusOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import AreaModal from './AreaModal';

const AreaManager = (props) => {
  const {
    value, onChange, areas, zoomed, onReset, onDefine, onDelete,
  } = props;

  const [openDefineArea, setOpenDefineArea] = React.useState(false);

  function handleMenuClick(e, _area) {
    if (e.key === '1') {
      onChange({ target: { value: _area.id } });
      setOpenDefineArea(_area);
    }
  }

  return (
    <>
      <Radio.Group value={value} onChange={onChange} size="small" style={{ marginRight: 0 }}>
        {areas.map((_area) => (
          <Radio.Button
            key={_area.id}
            value={_area.id}
          >
            <Badge color={_area.color} text={_area.name} />
            <Dropdown
              overlay={(
                <Menu onClick={(e) => handleMenuClick(e, _area)}>
                  <Menu.Item key="1" icon={<EditOutlined />}>
                    Edit
                  </Menu.Item>
                  <Menu.Item
                    key="2"
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      Modal.confirm({
                        title: 'Are you sure you want to delete this area?',
                        icon: <ExclamationCircleOutlined />,
                        okText: 'Delete',
                        okType: 'danger',
                        cancelText: 'Cancel',
                        onOk() {
                          onDelete(_area.id);
                        },
                      });
                    }}
                  >
                    Delete
                  </Menu.Item>
                </Menu>
                )}
              size="small"
            >
              <Button size="small" type="text" style={{ paddingRight: 0 }}>
                <DownOutlined />
              </Button>
            </Dropdown>
          </Radio.Button>
        ))}
        {zoomed && !value && (
        <Button
          icon={<PlusOutlined />}
          size="small"
          onClick={() => { setOpenDefineArea(true); }}
        >
          Define Area
        </Button>
        )}
        {value && (
        <Button icon={<ReloadOutlined />} size="small" onClick={onReset}>
          Reset
        </Button>
        )}
      </Radio.Group>
      {openDefineArea && (
      <AreaModal
        open={openDefineArea}
        onDefineArea={onDefine}
        onClose={() => { setOpenDefineArea(false); }}
      />
      )}
    </>
  );
};

AreaManager.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  areas: PropTypes.array.isRequired,
  zoomed: PropTypes.bool.isRequired,
  onReset: PropTypes.func.isRequired,
  onDefine: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default AreaManager;
