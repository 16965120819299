import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';

const intervals = ['1d', '2d', '1w', '4w'];

const IntervalSelector = (props) => {
  const { value, onChange } = props;
  return (
    <Radio.Group value={value} onChange={onChange}>
      {intervals.map((i) => <Radio.Button key={i} value={i}>{i}</Radio.Button>)}
    </Radio.Group>
  );
};

IntervalSelector.defaultProps = {
  value: intervals[3],
};

IntervalSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default IntervalSelector;
