import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  Card, Col, List, Row, Spin, Typography,
} from 'antd';
import { useHistory } from 'react-router';
import { getRequest } from '../../../services/request';
import { selectors as appSelectors } from '../../App/slice';
import UserAvatar from '../../Common/UserAvatar';

const SessionsList = () => {
  const [loading, setLoading] = React.useState(false);
  const user = useSelector(appSelectors.makeSelectUser());
  const [sessionUsers, setSessionUsers] = React.useState([]);
  const history = useHistory();

  React.useEffect(() => {
    setLoading(true);
    const searchParams = new URLSearchParams({
      filter: JSON.stringify({ userId: user.id, owner: false, include: ['session'] }),
    });
    getRequest(`/session-users?${searchParams}`)
      .then(({ data }) => {
        setSessionUsers(data);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, [user]);

  return (
    <>
      <Row justify="space-between" style={{ marginBottom: '16px' }}>
        <Col>
          <Typography.Title level={4}>Sessions shared with me</Typography.Title>
          <Typography.Text>Sessions that are explicitly shared with you</Typography.Text>
        </Col>
      </Row>
      <Spin spinning={loading}>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 4,
            lg: 4,
            xl: 6,
            xxl: 3,
          }}
          dataSource={sessionUsers}
          renderItem={(su) => (
            <List.Item key={su.id}>
              <Card>
                <Row gutter={24} align="middle">
                  <List.Item.Meta
                    avatar={(
                      <UserAvatar {...su.session.owner} size="large">
                        {!su.session.owner.photoUrl
                          ? (su.session.owner.name || su.session.owner.email)
                            .substring(0, 1)
                            .toUpperCase()
                          : null}
                      </UserAvatar>
)}
                    title={(
                      <Typography.Link
                        onClick={() => { history.push(`sessions/${su.sessionId}`); }}
                      >
                        {su.session.name}
                      </Typography.Link>
)}
                    description={su.session.owner.email}
                  />
                  <Typography.Text>
                    {moment(su.session.startTime).format('lll')}
                  </Typography.Text>
                </Row>
              </Card>
            </List.Item>
          )}
        />
      </Spin>
    </>
  );
};

export default SessionsList;
