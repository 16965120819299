/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import {
  Card, Col, message, Row, Space, Typography,
} from 'antd';
import { AuthContext } from '../AuthContextProvider';
import { selectors as appSelectors } from '../../App/slice';
import { SECONDARY_COLOR } from '../../../constants';

const VerifyPage = () => {
  const loading = useSelector(appSelectors.makeSelectLoading());
  const { currentUser, signOut } = React.useContext(AuthContext);

  const onSendClickHandler = async () => {
    try {
      await currentUser.sendEmailVerification();
      message.success('Verification email sent');
    } catch (err) {
      message.error('Some error occured. Please try again in some time.');
    }
  };

  if (loading) {
    return (
      <Helmet>
        <title>Verify</title>
        <meta name="description" content="Verify" />
      </Helmet>
    );
  }

  return (
    <>
      <Helmet>
        <title>Verify</title>
        <meta name="description" content="Verify" />
      </Helmet>
      <Row justify="center" align="middle" style={{ height: '100vh', background: SECONDARY_COLOR }}>
        <Col xxl={5} xl={6} lg={8} md={12} sm={12} xs={20}>
          <Card>
            <Typography>
              <Typography.Title>Welcome</Typography.Title>
              <Typography.Text>
                Thanks for signing up! You must have received a verification email.
                We just need you to verify your email address to complete setting
                up your account.
              </Typography.Text>
            </Typography>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
              <Typography style={{ textAlign: 'center' }}>
                <Typography.Text>
                  {'Didn\'t receive a verification email? '}
                </Typography.Text>
                <Typography.Link onClick={onSendClickHandler}>Send again</Typography.Link>
              </Typography>
              <Typography style={{ textAlign: 'center' }}>
                <Typography.Text>Or </Typography.Text>
                <Typography.Link onClick={signOut}>Sign in</Typography.Link>
                <Typography.Text> to another account</Typography.Text>
              </Typography>
            </Space>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default VerifyPage;
