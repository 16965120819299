import React from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import { Spin } from 'antd';

const Chart = (props) => {
  const {
    series, id, height, group, colors, yFormatter, min, max, xaxis, markers, animations,
    annotations, multiple, brushSeries, type,
  } = props;

  const getYAxis = () => series.slice(0, multiple ? undefined : 1).map((e, index) => {
    const values = e.data.map((item) => item[1]).filter((item) => item !== null);
    return ({
      title: {
        text: multiple ? e.name : series.map((s) => s.name).join(','),
      },
      forceNiceScale: true,
      opposite: index % 2 !== 0,
      labels: {
        minWidth: 40,
        formatter: (v) => yFormatter(e.key, v),
      },
      min: (values.length > 0 && multiple) ? Math.min(...values) : (_min) => _min,
      max: (values.length > 0 && multiple) ? Math.max(...values) : (_max) => _max,
    });
  });

  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState({
    chart: {
      animations,
      id,
      group,
      height,
      toolbar: {
        show: false,
        tools: {
          download: false,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
      },
      zoom: {
        enabled: true,
        type: 'x',
        autoScaleYaxis: false,
      },
    },
    colors,
    dataLabels: {
      enabled: false,
    },
    legend: {
      showForNullSeries: false,
    },
    markers,
    stroke: {
      show: true,
      curve: 'straight',
      lineCap: 'round',
      colors,
      width: 2,
      dashArray: 0,
    },
    xaxis: {
      min,
      max,
      ...xaxis,
    },
    yaxis: getYAxis(),
    annotations,
  });

  React.useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setOptions({ ...options, type });
      setLoading(false);
    }, (300));
  }, [type]);

  React.useEffect(() => {
    setTimeout(() => {
      setOptions({
        ...options, yaxis: getYAxis(), annotations, multiple,
      });
    }, (300));
  }, [annotations, multiple]);

  if (loading) {
    return (
      <Spin spinning={loading}>
        <div style={{ height: height + 130 }} />
      </Spin>
    );
  }

  return (
    <>
      <ReactApexChart
        options={options}
        series={series}
        height={height}
        type={type}
      />
      {brushSeries ? (
        <ReactApexChart
          options={{
            chart: {
              animations,
              id: 'brush',
              height: 130,
              type: 'area',
              brush: {
                target: id,
                enabled: true,
                autoScaleYaxis: false,
              },
              selection: {
                enabled: true,
                xaxis: {
                  min: Math.min(...brushSeries.data.map((e) => e[0]).filter((e) => e !== null)),
                  max: Math.max(...brushSeries.data.map((e) => e[0]).filter((e) => e !== null)),
                },
              },
              toolbar: {
                show: false,
              },
            },
            colors,
            dataLabels: {
              enabled: false,
            },
            legend: {
              showForNullSeries: false,
            },
            markers,
            xaxis: {
              ...xaxis,
              tooltip: {
                enabled: false,
              },
            },
            yaxis: {
              forceNiceScale: true,
              tickAmount: 2,
              labels: {
                minWidth: 40,
                formatter: () => '',
              },
            },
            annotations,
          }}
          series={[brushSeries]}
          height={130}
          type="area"
        />
      ) : null}
    </>
  );
};

Chart.defaultProps = {
  height: 360,
  group: undefined,
  colors: undefined,
  opacity: undefined,
  yFormatter: undefined,
  min: undefined,
  max: undefined,
  xaxis: {
    type: 'datetime',
    tooltip: {
      enabled: false,
    },
  },
  markers: {
    size: 0,
    hover: {
      sizeOffset: 3,
    },
  },
  animations: { enabled: true },
  annotations: { xaxis: [] },
  events: {
    animationEnd: undefined,
    beforeMount: undefined,
    mounted: undefined,
    updated: undefined,
    click: undefined,
    mouseMove: undefined,
    legendClick: undefined,
    markerClick: undefined,
    selection: undefined,
    dataPointSelection: undefined,
    dataPointMouseEnter: undefined,
    dataPointMouseLeave: undefined,
    beforeZoom: undefined,
    beforeResetZoom: undefined,
    zoomed: undefined,
    scrolled: undefined,
  },
  multiple: true,
  type: 'area',
};

Chart.propTypes = {
  id: PropTypes.string.isRequired,
  height: PropTypes.number,
  group: PropTypes.string,
  series: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  })).isRequired,
  brushSeries: PropTypes.object.isRequired,
  colors: PropTypes.arrayOf(PropTypes.string.isRequired),
  opacity: PropTypes.number,
  yFormatter: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  xaxis: PropTypes.object,
  markers: PropTypes.object,
  animations: PropTypes.object,
  annotations: PropTypes.object,
  events: PropTypes.object,
  multiple: PropTypes.bool,
  type: PropTypes.string,
};

export default Chart;
