import { Button } from 'antd';
import React from 'react';
// import PropTypes from 'prop-types';
import { AuthContext } from '../../AuthContextProvider';

const GoogleSignInButton = () => {
  const { signInWithGoogle } = React.useContext(AuthContext);

  const onGoogleSignInHandler = () => {
    signInWithGoogle();
  };

  return (
    <Button type="primary" size="large" onClick={onGoogleSignInHandler} style={{ width: '100%' }}>
      Sign in with Google
    </Button>
  );
};

GoogleSignInButton.propTypes = {};

export default GoogleSignInButton;
