import { Space, Typography } from 'antd';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import _ from 'lodash';

import { useAntdTable, useRequest } from 'ahooks';
import { useSelector } from 'react-redux';
import ApplyTagsButton from './ApplyTagsButton';
import ColumnsButton from './ColumnsButton';
import ShareButton from './ShareButton';
import SessionsTable from './SessionsTable';
import { PAGE_SIZE } from '../../constants';
import { selectors as appSelectors } from '../App/slice';
import model from './model';
import preferencesRepo from '../../repos/preferences';

const SessionsPage = () => {
  const location = useLocation();
  const history = useHistory();

  const user = useSelector(appSelectors.makeSelectUser());

  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);

  const { data: tags, loading } = useRequest(
    () => model.fetchTags(user),
    { cacheKey: 'usertags', refreshDeps: [user] },
  );

  const { data: userPreferences, loading: prefLoading, run: fetchUserPreferences } = useRequest(
    () => preferencesRepo.fetch(user),
    { cacheKey: 'userPreferences', refreshDeps: [user] },
  );

  const { loading: updatePrefLoading, run: runUpdatePref } = useRequest(
    (additionalColumns) => {
      if (userPreferences === undefined) {
        return preferencesRepo.create(user.id, { ...userPreferences, additionalColumns });
      }
      return preferencesRepo.update(user.id, { ...userPreferences, additionalColumns });
    },
    {
      manual: true,
      onSuccess: fetchUserPreferences,
    },
  );

  const { tableProps, refresh } = useAntdTable(
    (params) => model.fetchSessions(params, location),
    { defaultPageSize: PAGE_SIZE, refreshDeps: [location] },
  );

  if (loading) {
    return null;
  }

  const selectedSessions = tableProps.dataSource?.filter((s) => selectedRowKeys.includes(s.id));
  const selectedTags = _.uniq(_.flatMap(
    selectedSessions.map((s) => (s.meta ? s.meta.tags : null)).filter((s) => s),
  ));

  const updateAdditionalColumns = (columns) => {
    runUpdatePref(columns);
  };

  const values = userPreferences
    ? userPreferences.additionalColumns.map((e) => e.value)
    : [];
  const additionalColumns = userPreferences
    ? userPreferences.additionalColumns
    : [];

  return (
    <>
      <Space style={{ marginBottom: '16px' }}>
        <ApplyTagsButton
          tags={tags}
          selectedTags={selectedTags}
          selectedSessions={selectedSessions}
          onSubmit={refresh}
        />
        <ShareButton selectedSessions={selectedSessions} onSubmit={refresh} />
        <ColumnsButton
          onOk={updateAdditionalColumns}
          values={values}
        />
        <Typography>
          <Typography.Text>
            {selectedRowKeys.length ? `${selectedRowKeys.length} row(s) selected` : ''}
          </Typography.Text>
        </Typography>
      </Space>

      <SessionsTable
        sessions={tableProps.dataSource}
        tags={tags}
        loading={tableProps.loading || updatePrefLoading || prefLoading}
        pagination={tableProps.pagination}
        onIdClick={(id) => { history.push(`/sessions/${id}`); }}
        onRowSelectionChange={setSelectedRowKeys}
        onTableChange={tableProps.onChange}
        additionalColumns={additionalColumns}
      />
    </>
  );
};

export default SessionsPage;
