import {
  LockOutlined, LogoutOutlined, SettingOutlined, UserOutlined,
} from '@ant-design/icons';
import {
  Avatar, Button, Dropdown, Layout, Menu, PageHeader,
} from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { SECONDARY_COLOR } from '../../../constants';
import { actions as appActions, selectors } from '../../App/slice';
import { AuthContext } from '../../Auth/AuthContextProvider';
import ChangePasswordDialog from '../../Auth/ChangePasswordDialog';
import routes from './routes';

const AppHeader = () => {
  const history = useHistory();
  const location = useLocation();
  const user = useSelector(selectors.makeSelectUser());
  const dispatch = useDispatch();
  const { signOut, currentUser } = React.useContext(AuthContext);

  const [openChangePassword, setOpenChangePassword] = React.useState(false);

  const handleMenuClick = async ({ key }) => {
    switch (key) {
      case 'change':
        setOpenChangePassword(true);
        break;
      case 'logout':
        await signOut();
        dispatch(appActions.setUser(null));
        break;
      default:
        history.push(`/${key}`);
        break;
    }
  };

  const onChangePasswordCloseHandler = () => {
    setOpenChangePassword(false);
  };

  const renderMenu = () => {
    const menuItems = [];
    if (user) {
      menuItems.push(<Menu.Item key="profile" icon={<UserOutlined />}>Profile</Menu.Item>);
      menuItems.push(<Menu.Item key="settings" icon={<SettingOutlined />}>Account Settings</Menu.Item>);
    }
    if (currentUser.providerData.some((p) => p.providerId === 'password')) {
      menuItems.push(<Menu.Item key="change" icon={<LockOutlined />}>Change Password</Menu.Item>);
    }
    menuItems.push(<Menu.Item key="logout" icon={<LogoutOutlined />}>Logout</Menu.Item>);

    return <Menu onClick={handleMenuClick}>{menuItems}</Menu>;
  };

  return (
    <Layout.Header style={{ padding: 0, background: SECONDARY_COLOR }}>
      <PageHeader
        ghost={false}
        title={routes.find((r) => r.path === location.pathname)
            && routes.find((r) => r.path === location.pathname).name}
        extra={currentUser && (
        <Dropdown overlay={renderMenu}>
          <Button type="text">
            <Avatar size="small" src={currentUser.photoURL} />
            {currentUser.displayName}
          </Button>
        </Dropdown>
        )}
      />
      <ChangePasswordDialog open={openChangePassword} onClose={onChangePasswordCloseHandler} />
    </Layout.Header>
  );
};

export default AppHeader;
