import React from 'react';
import preval from 'preval.macro';
import { Layout } from 'antd';

const AppFooter = () => {
  const dateTimeStamp = preval`module.exports = new Date().toLocaleString();`;
  return (
    <Layout.Footer style={{ textAlign: 'center' }}>
      <div>{`BreathQI © ${new Date().getFullYear()}`}</div>
      <div>{`Build date - ${dateTimeStamp}`}</div>
    </Layout.Footer>
  );
};

export default AppFooter;
