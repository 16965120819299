import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Collapse, Descriptions, Row, Typography,
} from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { useSelector } from 'react-redux';
import countries from '../../../constants/countries';
import { selectors as appSelectors } from '../../App/slice';

const ProfileInfo = (props) => {
  const { profile } = props;
  const genders = useSelector(appSelectors.makeSelectGenderCategory());
  const fitnessLevels = useSelector(appSelectors.makeSelectFitnessLevelCategory());
  const bodyTypes = useSelector(appSelectors.makeSelectBodyTypeCategory());
  const medicalConditions = useSelector(appSelectors.makeSelectMedicalConditionCategory());
  return (
    <Collapse style={{ marginBottom: '16px', backgroundColor: 'white', borderColor: '#f0f0f0' }}>
      <Collapse.Panel
        header={(
          <Row align="middle" gutter={12}>
            <Col>
              <Avatar src={profile.photoUrl}>
                {!profile.photoUrl
                  ? (profile.name || profile.email).substring(0, 1).toUpperCase()
                  : null}
              </Avatar>
            </Col>
            <Col>
              <Typography.Text>{profile.name}</Typography.Text>
            </Col>
          </Row>
        )}
      >
        <Descriptions
          size="small"
          column={{
            xxl: 4, xl: 3, lg: 2, md: 2, sm: 1,
          }}
        >
          <Descriptions.Item label="Email">{profile.email}</Descriptions.Item>
          <Descriptions.Item label="Country">{[countries.find((c) => c.code === profile.country)].map((c) => `${c.emoji} ${c.name}`)}</Descriptions.Item>
          <Descriptions.Item label="Phone">{profile.phone || '--'}</Descriptions.Item>
          <Descriptions.Item label="Gender">{genders.find((ref) => ref.id === profile.genderRefId)?.value}</Descriptions.Item>
          <Descriptions.Item label="Birth">{`${profile.birthMonth}/${profile.birthYear}`}</Descriptions.Item>
          <Descriptions.Item label="Fitness Level">{fitnessLevels.find((ref) => ref.id === profile.fitnessLevelRefId)?.value}</Descriptions.Item>
          <Descriptions.Item label="Body Type">{bodyTypes.find((ref) => ref.id === profile.bodyTypeRefId)?.value}</Descriptions.Item>
          <Descriptions.Item label="Medical Condition">{medicalConditions.find((ref) => ref.id === profile.medicalConditionRefId)?.value}</Descriptions.Item>
          <Descriptions.Item label="Other">{profile.other || '--'}</Descriptions.Item>
        </Descriptions>
      </Collapse.Panel>
    </Collapse>
  );
};

ProfileInfo.defaultProps = {
  profile: {
    photoUrl: '',
    name: '',
    email: '',
    country: '',
    phone: '',
    genderRefId: undefined,
    birthMonth: undefined,
    birthYear: undefined,
    fitnessLevelRefId: undefined,
    bodyTypeRefId: undefined,
    medicalConditionRefId: undefined,
    other: '',
  },
};

ProfileInfo.propTypes = {
  profile: PropTypes.shape({
    photoUrl: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    country: PropTypes.string,
    phone: PropTypes.string,
    genderRefId: PropTypes.number,
    birthMonth: PropTypes.number,
    birthYear: PropTypes.number,
    fitnessLevelRefId: PropTypes.number,
    bodyTypeRefId: PropTypes.number,
    medicalConditionRefId: PropTypes.number,
    other: PropTypes.string,
  }),
};

export default ProfileInfo;
