import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectors } from '../../App/slice';
import { AuthContext } from '../../Auth/AuthContextProvider';
import AppHeader from './header';
import AppDrawer from './drawer';
import AppBreadcrumb from './breadcrumb';
import AppFooter from './footer';

const AppLayout = ({ children }) => {
  const user = useSelector(selectors.makeSelectUser());
  const { currentUser } = React.useContext(AuthContext);

  let redirect;

  if (!currentUser) {
    return <Redirect to="/login" />;
  }

  if (!user && !currentUser.emailVerified) {
    redirect = <Redirect to="/verify" />;
  } else if (!user && currentUser.emailVerified) {
    redirect = <Redirect to="/onboard" />;
  }

  const showDrawer = !redirect;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {showDrawer && <AppDrawer />}
      <Layout>
        <AppHeader />
        <Layout.Content style={{ margin: '16px 16px' }}>
          <AppBreadcrumb />
          {children}
          {redirect}
        </Layout.Content>
        <AppFooter />
      </Layout>
    </Layout>
  );
};

AppLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default AppLayout;
