import {
  Button, Card, Col, List, message, Modal, Popconfirm, Row, Spin, Typography,
} from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { deleteRequest, getRequest, postRequest } from '../../../services/request';
import { selectors } from '../../App/slice';
import UserAutoComplete from '../../Common/UserAutoComplete';
import UserAvatar from '../../Common/UserAvatar';

const FriendsList = () => {
  const user = useSelector(selectors.makeSelectUser());
  const [sharedUsers, setSharedUsers] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selected, setSelected] = React.useState([]);

  const init = React.useCallback(() => {
    setLoading(true);
    const params = new URLSearchParams({ filter: JSON.stringify({ userId: user.id }) });
    getRequest(`/shared-users?${params}`).then(({ data }) => {
      setSharedUsers(data);
      setLoading(false);
    });
  }, [user]);

  React.useEffect(() => { init(); }, [init]);

  const onSubmitHandler = async () => {
    try {
      if (selected.length > 0) {
        setSubmitting(true);
        try {
          await Promise.all(selected.map((e) => postRequest('/shared-users', { userId: user.id, sharedWithId: e.key })));
          setSubmitting(false);
          setShow(false);
          init();
        } catch (err) {
          setSubmitting(false);
        }
      }
    } catch (err) {
      //
    }
  };

  const onRemoveClick = (id) => async () => {
    try {
      await deleteRequest(`/shared-users/${id}`);
      message.success('Removed');
      init();
    } catch (err) {
      message.error(err);
    }
  };

  const onSelectHandler = (val, { key, value }) => {
    setSelected((prev) => [...prev, { key, value }]);
  };

  const onDeselectHandler = (value) => {
    setSelected(selected.filter((e) => e.value !== value));
  };

  return (
    <>
      <Row justify="space-between" style={{ marginBottom: '16px' }}>
        <Col>
          <Typography.Title level={4}>Friends</Typography.Title>
          <Typography.Text>Friends can view all your sessions</Typography.Text>
        </Col>
        <Col>
          <Button type="primary" onClick={() => { setShow(true); }}>Add friend</Button>
        </Col>
      </Row>
      <Spin spinning={loading}>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 4,
            lg: 4,
            xl: 6,
            xxl: 3,
          }}
          dataSource={sharedUsers}
          renderItem={(u) => (
            <List.Item key={u.sharedWith.email}>
              <Card>
                <Row gutter={24}>
                  <List.Item.Meta
                    avatar={<UserAvatar {...u.sharedWith} size="large" />}
                    title={u.sharedWith.name || u.sharedWith.email}
                    description={u.sharedWith.email}
                  />
                  <Popconfirm
                    title={`Remove ${u.sharedWith.name || u.sharedWith.email} from your friends?`}
                    onConfirm={onRemoveClick(u.id)}
                    okText="Remove"
                    cancelText="Cancel"
                  >
                    <Button danger>Remove</Button>
                  </Popconfirm>
                </Row>
              </Card>
            </List.Item>
          )}
        />
      </Spin>
      <Modal
        visible={show}
        title="Add a friend"
        onOk={onSubmitHandler}
        confirmLoading={submitting}
        onCancel={() => { setShow(false); }}
      >
        <UserAutoComplete
          onSelect={onSelectHandler}
          onDeselect={onDeselectHandler}
        />
      </Modal>
    </>
  );
};

export default FriendsList;
