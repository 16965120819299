import React from 'react';
import PropTypes from 'prop-types';
import {
  Badge, Divider, Modal, Select, Space, Tabs, Tag, Tooltip,
} from 'antd';
import { PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { selectors } from '../../App/slice';

const selectStyle = { width: '100%' };

const KeySelect = (props) => {
  const {
    onOk, onCancel, values,
  } = props;

  const keys = useSelector(selectors.makeSelectKeys());

  const [showModal, setShowModal] = React.useState(false);
  const [selected, setSelected] = React.useState(values);
  const [inputVisible, setInputVisible] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const inputRef = React.useRef();

  React.useEffect(() => {
    setSelected(values);
  }, [values]);

  const handleChange = (key, checked) => {
    const nextSelected = checked ? [...selected, key] : selected.filter((t) => t !== key);
    setSelected(nextSelected);
  };

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputVisible]);

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e);
  };

  const handleInputConfirm = () => {
    if (inputValue && selected.indexOf(inputValue) === -1) {
      setSelected([...selected, inputValue]);
    }
    setInputVisible(false);
    setInputValue('');
  };

  const renderCheckableTag = (group) => (
    <Space wrap size={[8, 8]}>
      {keys.trendsKeys
        .find((key) => key.overview && key.label === group)
        .children
        .map(({ value, label, description }) => (
          <Tag.CheckableTag
            style={{ marginRight: 0 }}
            key={value}
            checked={selected.indexOf(value) > -1}
            onChange={(checked) => handleChange(value, checked)}
          >
            <Tooltip title={description} overlayInnerStyle={{ width: 500 }}>
              <QuestionCircleOutlined style={{ marginRight: 8, color: selected.indexOf(value) > -1 ? '#fff' : 'darkgray' }} />
            </Tooltip>
            {label}
          </Tag.CheckableTag>
        ))}
    </Space>
  );

  const renderTabTitle = (group) => {
    const count = keys.trendsKeys
      .find((key) => key.overview && key.label === group)
      .children
      .filter((key) => selected.includes(key.value))
      .length;
    return (
      <span>
        {group}
        <Badge count={count} showZero={false} style={{ marginLeft: 8 }} />
      </span>
    );
  };

  const handleOk = () => {
    setShowModal(false);
    onOk(selected);
  };

  const handleCancel = () => {
    setSelected(values);
    setShowModal(false);
    onCancel();
  };

  const handleKeyDeselect = (key) => {
    const filtered = selected.filter((e) => e !== key);
    setSelected(filtered);
    onOk(filtered);
  };

  return (
    <>
      <Select
        style={selectStyle}
        onClick={() => {
          setShowModal(true);
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            setShowModal(true);
          }
        }}
        open={false}
        mode="multiple"
        value={selected}
        dropdownMatchSelectWidth={false}
        onDeselect={handleKeyDeselect}
        placeholder="Click to add chart"
      />
      <Modal visible={showModal} title="Select measurements" width={700} onOk={handleOk} onCancel={handleCancel} destroyOnClose>
        <p>Selected measurements:</p>
        <Space wrap size={[8, 8]}>
          {selected.map((key) => (
            <Tag
              key={key}
              style={{ marginRight: 0 }}
              closable
              onClose={() => { setSelected(selected.filter((e) => e !== key)); }}
            >
              {_.flatMap(keys.trendsKeys.map((e) => e.children)).find((e) => e.value === key).label}
            </Tag>
          ))}
          {inputVisible && (
          <Select
            ref={inputRef}
            showSearch
            filterOption={(input, option) => option.children
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0}
            style={{ width: 240 }}
            size="small"
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
          >
            {_.flatMap(keys.trendsKeys.map((e) => e.children)).map(({ value, label }) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
          )}
          {!inputVisible && (
          <Tag onClick={showInput}>
            <PlusOutlined />
            {' '}
            Add Measurement
          </Tag>
          )}
        </Space>
        <Divider />
        <Tabs size="small">
          <Tabs.TabPane tab={renderTabTitle('CO2')} key="1">
            {renderCheckableTag('CO2')}
          </Tabs.TabPane>
          <Tabs.TabPane tab={renderTabTitle('Oxygen')} key="2">
            {renderCheckableTag('Oxygen')}
          </Tabs.TabPane>
          <Tabs.TabPane tab={renderTabTitle('Metabolism')} key="3">
            {renderCheckableTag('Metabolism')}
          </Tabs.TabPane>
          <Tabs.TabPane tab={renderTabTitle('Lung Function/Breathing')} key="4">
            {renderCheckableTag('Lung Function/Breathing')}
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

KeySelect.defaultProps = {
  onCancel: () => {},
};

KeySelect.propTypes = {
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  values: PropTypes.array.isRequired,
};

export default KeySelect;
