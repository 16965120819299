import React from 'react';
import store from './index';

const useAttachReducer = ({ key, reducer }) => {
  React.useEffect(() => {
    store.attachReducers({ [key]: reducer });
    return () => {
      store.detachReducers([key]);
    };
  }, [key, reducer]);
};

export default useAttachReducer;
