import React from 'react';
// import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import {
  Card, Empty, Row, Spin, Typography,
} from 'antd';
import { useSelector } from 'react-redux';

import { useRequest } from 'ahooks';
import { selectors as appSelectors } from '../App/slice';
import MeasurementSelector from './MeasurementSelector';
import ProfileInfo from './ProfileInfo';
import Overview from './Overview';
import preferencesRepo from '../../repos/preferences';
import sessionsRepo from '../../repos/sessions';

const SessionPage = () => {
  const { id: sessionId } = useParams();
  const user = useSelector(appSelectors.makeSelectUser());

  const { data: { session, profile }, loading: sessionLoading, error } = useRequest(
    () => sessionsRepo.fetchSession(sessionId, { data: false }),
    {
      cacheKey: `session#${sessionId}`,
      refreshDeps: [user],
      initialData: { session: undefined, profile: undefined },
      formatResult: (data) => {
        const _session = data;
        if (_session && _session.owner?.id !== user.id && _session.withProfile) {
          return { session: _session, profile: _session.owner };
        }
        return { session: _session };
      },
    },
  );

  const { data: userPreferences, loading: prefLoading, run: fetchUserPreferences } = useRequest(
    () => preferencesRepo.fetch(user),
    { cacheKey: 'userPreferences', refreshDeps: [user] },
  );

  const { loading: updatePrefLoading, run: runUpdatePref } = useRequest(
    (sessionKeys) => {
      if (userPreferences === undefined) {
        return preferencesRepo.create(user.id, { ...userPreferences, sessionKeys });
      }
      return preferencesRepo.update(user.id, { ...userPreferences, sessionKeys });
    },
    {
      manual: true,
      onSuccess: fetchUserPreferences,
    },
  );

  if (error) {
    return (
      <Row justify="center" align="middle" style={{ height: '100%' }}>
        <Empty description="Session not found" />
      </Row>
    );
  }

  if (sessionLoading) {
    return (
      <Row justify="center" align="middle" style={{ height: '100%' }}>
        <Spin size="large" />
      </Row>
    );
  }

  return (
    <>
      <Helmet>
        <title>Session</title>
        <meta name="description" content="Description of SessionPage" />
      </Helmet>
      <Card>
        <Typography.Title style={{ marginBottom: 16 }} level={2}>{session.name}</Typography.Title>

        {profile && <ProfileInfo profile={profile} />}

        <Overview sessionId={sessionId} />

        <Card size="small">
          <MeasurementSelector
            loading={prefLoading || updatePrefLoading}
            sessionId={session.id}
            startTime={session.first}
            endTime={session.last}
            defaultKeys={userPreferences?.sessionKeys}
            onSaveChart={runUpdatePref}
          />
        </Card>
      </Card>
    </>
  );
};

SessionPage.propTypes = {

};

export default SessionPage;
