import dynostore, { dynamicReducers } from '@redux-dynostore/core';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import appReducer from '../components/App/slice';

const middleware = [...getDefaultMiddleware()];
const enhancers = [dynostore(dynamicReducers())];

const store = configureStore({
  reducer: {
    App: appReducer,
  },
  middleware,
  preloadedState: {},
  devTools: process.env.NODE_ENV !== 'production',
  enhancers,
});

// if (process.env.NODE_ENV !== 'production' && module.hot) {
//   module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
// }

export default store;
