import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Space, Spin, message, Button, Col,
} from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Chart from '../../Common/Chart';
import {
  deleteRequest, getRequest, postRequest, putRequest,
} from '../../../services/request';
import IntervalSelector from './IntervalSelector';
import AxisTypeSelector from './AxisTypeSelector';
import AreaManager from './AreaManager';
import CycleSelector from './CycleSelector';
import KeySelect from './KeySelect';
import ExportExcel from './ExportExcel';
import { selectors } from '../../App/slice';

const darkerShadeStyle = {
  strokeDashArray: 0,
  fillColor: '#696969',
  borderColor: '#696969',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'fetching':
      return { ...state, spinning: true };
    case 'fetched':
      return { ...state, spinning: false, ...action.payload };
    default:
      return state;
  }
};

const MeasurementSelector = (props) => {
  const {
    defaultKeys, startTime, endTime, sessionId, onSaveChart, loading,
  } = props;
  const { labels, units, precisions } = useSelector(selectors.makeSelectKeys());
  const initialState = {
    spinning: false,
    series: defaultKeys.map((key) => ({ name: labels[key], data: [], key })),
  };
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [keys, setKeys] = React.useState(defaultKeys || []);
  const [interval, setInterval] = React.useState('500ms');
  const [range, setRange] = React.useState([startTime, endTime]);
  const [areas, setAreas] = React.useState([]);
  const [area, setArea] = React.useState();
  const [axisType, setAxisType] = React.useState('Multiple axis');
  const [zoomed, setZoomed] = React.useState(false);
  const [showBreaths, setShowBreaths] = React.useState(false);
  const [cycleType, setCycleType] = React.useState('CYCLES');
  const [zeroCrossings, setZeroCrossings] = React.useState([]);
  const [breaths, setBreaths] = React.useState([]);
  const [brushSeries, setBrushSeries] = React.useState({ name: 'brushSeries', key: 'VOLU', data: [] });

  React.useEffect(() => {
    setKeys(defaultKeys || []);
  }, [defaultKeys]);

  const sameUnits = React.useMemo(
    () => keys.map((key) => units[key])
      .filter((v, i, arr) => arr.indexOf(v) === i).length === 1,
    [keys],
  );

  const fetchBrushData = React.useCallback((_interval) => {
    const params = { sessionId, startTime, endTime };
    if (_interval !== 'NA') {
      params.interval = _interval;
    }

    const _params = new URLSearchParams({ ...params, key: 'VOLU' });
    getRequest(`/measurements/query?${_params}`).then((_brushSeries) => {
      setBrushSeries({ ...brushSeries, data: _brushSeries });
    });
  }, [sessionId, startTime, endTime]);

  const fetchData = React.useCallback((_keys, _interval) => {
    dispatch({ type: 'fetching' });
    if (_keys.length > 0) {
      const params = { sessionId, startTime, endTime };
      if (_interval !== 'NA') {
        params.interval = _interval;
      }
      const promises = _keys.map((key) => {
        const _params = new URLSearchParams({ ...params, key });
        return getRequest(`/measurements/query?${_params}`);
      });

      Promise.all(promises).then((results) => {
        const _series = _keys.map((key, index) => ({
          name: labels[key],
          key,
          data: results[index],
        }));

        dispatch({
          type: 'fetched',
          payload: { series: _series },
        });
      });
    } else {
      dispatch({
        type: 'fetched',
        payload: { series: [] },
      });
    }
  }, [sessionId, startTime, endTime]);

  React.useEffect(() => {
    const params = { sessionId, startTime, endTime };
    const promises = ['INST', 'EXST'].map((key) => {
      const _params = new URLSearchParams({ ...params, key });
      return getRequest(`/measurements/query?${_params}`);
    });

    Promise.all(promises).then((results) => {
      const [inhales, exhales] = results;
      const _breaths = [
        ...inhales.map((e) => [e[0], 'INST']),
        ...exhales.map((e) => [e[0], 'EXST']),
      ].sort((a, b) => a[0] - b[0]);
      setBreaths(_breaths);
    });
  }, [sessionId, startTime, endTime]);

  React.useEffect(() => {
    fetchBrushData(interval);
  }, [fetchBrushData, interval]);

  React.useEffect(() => {
    fetchData(keys, interval);
  }, [keys, fetchData, interval]);

  const fetchAreas = React.useCallback(() => {
    const _params = new URLSearchParams({ filter: JSON.stringify({ sessionId }) });
    getRequest(`/session-areas?${_params}`).then(({ data: _areas }) => {
      setAreas(_areas);
    });
  }, [sessionId]);

  React.useEffect(() => {
    fetchAreas();
  }, [fetchAreas]);

  React.useEffect(() => {
    if (!sameUnits) {
      setAxisType('Multiple axis');
    }
  }, [sameUnits]);

  const handleDefineArea = React.useCallback(({ id, name, color }) => {
    let promise;
    if (id) {
      promise = putRequest(`/session-areas/${id}`, {
        sessionId,
        name,
        color,
        startTime,
        endTime,
      });
    } else {
      promise = postRequest('/session-areas', {
        sessionId,
        name,
        color,
        startTime,
        endTime,
      });
    }
    return promise.then(() => {
      message.success('Area defined');
      fetchData(keys, interval);
      fetchAreas();
      setArea(undefined);
    });
  }, [
    fetchData, fetchAreas,
    keys, interval, sessionId, startTime, endTime,
  ]);

  const handleDeleteArea = React.useCallback((id) => {
    deleteRequest(`/session-areas/${id}`).then(() => {
      message.success('Area deleted');
      fetchData(keys, interval);
      fetchAreas();
    });
  }, [fetchData, fetchAreas, keys, interval]);

  const yFormatter = (key, v) => {
    const fractionDigits = precisions[key] || 1;
    const unit = units[key] || '';
    return `${parseFloat(v).toFixed(fractionDigits)} ${unit}`;
  };

  const formatter = React.useCallback((value) => {
    const startMoment = moment(startTime);
    const duration = moment.duration(moment(value).diff(startMoment, 's'), 's');
    return `${moment.utc(duration.asMilliseconds()).format('mm:ss')}`;
  }, [startTime]);

  const xaxis = React.useMemo(() => ({
    type: 'numeric',
    min: range[0],
    max: range[1],
    labels: { formatter },
    tooltip: { enabled: false },
  }), [formatter, range]);

  const events = React.useMemo(() => ({
    beforeZoom: (__, { xaxis: x }) => {
      const _range = [x.min < startTime ? startTime : x.min, x.max > endTime ? endTime : x.max];
      setRange(_range);
      return { xaxis: { min: _range[0], max: _range[1] } };
    },
    zoomed: (__, { xaxis: x }) => {
      setZoomed(x.min > startTime && x.max < endTime);
    },
    beforeResetZoom: () => {
      setRange([startTime, endTime]);
      return ({
        xaxis: {
          min: startTime,
          max: endTime,
        },
      });
    }
    ,
  }), [startTime, endTime]);

  React.useEffect(() => {
    if (showBreaths) {
      const _params = {
        sessionId, startTime, endTime, type: cycleType,
      };
      if (interval !== 'NA') {
        _params.interval = interval;
      }
      const params = new URLSearchParams(_params);
      getRequest(`/measurements/zero-crossings?${params}`).then((zc) => {
        setZeroCrossings(zc);
      });
    } else {
      setZeroCrossings([]);
    }
  }, [sessionId, cycleType, showBreaths, startTime, endTime, interval]);

  const _annotations = {
    xaxis: [
      ...zeroCrossings.map((e) => ({ x: e[0], x2: e[1], ...darkerShadeStyle })),
      ...areas.map((e) => ({
        x: moment(e.startTime).valueOf(),
        x2: moment(e.endTime).valueOf(),
        strokeDashArray: 0,
        fillColor: e.color,
        borderColor: e.color,
      })),
      ...(showBreaths ? breaths.map((e) => {
        const color = e[1] === 'INST' ? '#00E396' : '#775DD0';
        return {
          x: e[0],
          strokeDashArray: 0,
          borderColor: color,
          label: {
            borderColor: color,
            style: {
              color: '#fff',
              background: color,
            },
            text: e[1] === 'INST' ? 'I' : 'E',
          },
        };
      }) : []),
    ],
  };

  const handleIntervalChange = (e) => {
    setInterval(e.target.value);
  };

  const handleAxisTypeChange = (e) => {
    if (sameUnits) {
      setAxisType(e.target.value);
    } else {
      message.error('The selected measurments should have the same units to view them on a single axis', 8);
    }
  };

  const handleAreaChange = (e) => {
    setArea(e.target.value);
  };

  const handleCycleTypeChange = (type) => {
    setCycleType(type);
  };

  const handleShowBreathsChange = (checked) => {
    setShowBreaths(checked);
  };

  return (
    <Spin spinning={state.spinning || loading}>
      <Row justify="space-between">
        <Col xs={22}>
          <KeySelect
            onOk={(_keys) => { setKeys(_keys); }}
            values={keys}
          />
        </Col>
        <Button type="primary" disabled={keys.length === 0} onClick={() => { onSaveChart(keys); }}>Save chart</Button>
      </Row>

      {keys.length > 0 ? (
        <>
          <Chart
            id={keys.join(',')}
            series={state.series}
            brushSeries={brushSeries}
            yFormatter={yFormatter}
            xaxis={xaxis}
            animations={{ enabled: false }}
            annotations={_annotations}
            events={events}
            multiple={axisType === 'Multiple axis'}
          />

          <Row justify="space-between" align="middle">
            <Space direction="horizontal" style={{ margin: '16px 0px' }}>
              <IntervalSelector value={interval} onChange={handleIntervalChange} />
              <AxisTypeSelector value={axisType} onChange={handleAxisTypeChange} />
              <AreaManager
                value={area}
                onChange={handleAreaChange}
                areas={areas}
                zoomed={zoomed}
                onReset={() => {
                  setArea(undefined);
                }}
                onDefine={handleDefineArea}
                onDelete={handleDeleteArea}
              />
              <CycleSelector
                onShow={handleShowBreathsChange}
                onTypeChange={handleCycleTypeChange}
              />
            </Space>
            <ExportExcel
              sessionId={sessionId}
              startTime={startTime}
              endTime={endTime}
              keys={keys}
            />
          </Row>
        </>
      ) : null}
    </Spin>
  );
};

MeasurementSelector.defaultProps = {
  loading: false,
  defaultKeys: [],
};

MeasurementSelector.propTypes = {
  loading: PropTypes.bool,
  defaultKeys: PropTypes.arrayOf(PropTypes.string.isRequired),
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
  sessionId: PropTypes.string.isRequired,
  onSaveChart: PropTypes.func.isRequired,
};

export default MeasurementSelector;
