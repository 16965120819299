import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Splash from '../../Common/Splash';
import Auth from '../../../services/auth';

export const AuthContext = React.createContext({
  currentUser: null,
  setCurrentUser: null,
  createUserWithEmailAndPassword: null,
  signInWithEmailAndPassword: null,
  signInWithGoogle: null,
  signOut: null,
  reloadCurrentUser: null,
  applyActionCode: null,
  sendPasswordResetEmail: null,
  setToken: null,
});

const auth = new Auth();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const onAuthStateChange = () => auth.onAuthStateChanged(async (user) => {
      if (user) {
        auth.setToken().then(() => {
          setCurrentUser(user);
        });
      } else {
        auth.clearToken();
        setCurrentUser(null);
      }
    });

    const unsubscribe = onAuthStateChange();
    return () => {
      unsubscribe();
    };
  }, [dispatch]);

  if (currentUser === false) {
    return <Splash />;
  }

  const signInWithEmailAndPassword = async (
    email,
    password,
  ) => auth.signInWithEmailAndPassword(email, password);

  const createUserWithEmailAndPassword = async ({
    name,
    email,
    password,
  }) => auth.createUserWithEmailAndPassword({ name, email, password });

  const signInWithGoogle = async () => auth.signInWithGoogle();

  const signOut = async () => {
    await auth.signOut();
    setCurrentUser(null);
  };

  const applyActionCode = async (code) => {
    await auth.applyActionCode(code);
    await auth.signedInUser().reload();
    auth.setToken().then(() => {
      setCurrentUser(auth.signedInUser());
    });
  };

  const sendPasswordResetEmail = (email) => auth.sendPasswordResetEmail(email);

  const confirmPasswordReset = (code, newPassword) => auth.confirmPasswordReset(code, newPassword);

  const verifyPasswordResetCode = (code) => auth.verifyPasswordResetCode(code);

  const { setToken } = auth;

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        signOut,
        signInWithGoogle,
        applyActionCode,
        sendPasswordResetEmail,
        confirmPasswordReset,
        verifyPasswordResetCode,
        setToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
