import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Col, Collapse, Descriptions, Row, Tooltip, Typography,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { getRequest } from '../../../services/request';
import { selectors as appSelectors } from '../../App/slice';

const Overview = (props) => {
  const user = useSelector(appSelectors.makeSelectUser());
  const [data, setData] = React.useState({});
  const [groups, setGroups] = React.useState([]);

  React.useEffect(() => {
    const params = new URLSearchParams({
      filter: JSON.stringify({ userId: user.id, sessionId: props.sessionId }),
    });
    getRequest(`/measurements/trends?${params}`).then(({ trends, groups: _groups }) => {
      if (trends && trends.length > 0) {
        setData(trends[0]);
      }
      setGroups(_groups);
    });
    // eslint-disable-next-line react/destructuring-assignment
  }, [props.sessionId, user.id]);

  return (
    <Collapse style={{ marginBottom: '16px', backgroundColor: 'white', borderColor: '#f0f0f0' }}>
      <Collapse.Panel header={(<Typography.Text>Session Overview</Typography.Text>)}>
        <Row gutter={[16, 16]}>

          {groups.map((group) => (
            <Col key={group.label} xs={12}>
              <Descriptions bordered size="small" column={1} title={group.label}>
                {group.children.map((key) => {
                  let value;
                  if (data[key.value] !== null && data[key.value] !== undefined) {
                    value = data[key.value];
                    if (key.div) {
                      value /= key.div;
                    }

                    let { unit } = key;
                    if (unit === 'L' && value < 1) {
                      value *= 1000;
                      unit = 'ml';
                    }

                    value = `${value.toFixed(2)} ${unit}`;
                  }
                  return (
                    <Descriptions.Item
                      key={key.value}
                      label={(
                        <>
                          <Tooltip title={key.description} overlayInnerStyle={{ width: 500 }}>
                            <QuestionCircleOutlined style={{ marginRight: 8, color: 'darkgray' }} />
                          </Tooltip>
                          {key.label}
                        </>
                      )}
                    >
                      {value || '--'}
                    </Descriptions.Item>
                  );
                })}
              </Descriptions>
            </Col>
          ))}
        </Row>
      </Collapse.Panel>
    </Collapse>
  );
};

Overview.propTypes = {
  sessionId: PropTypes.string.isRequired,
};

export default Overview;
