import { initializeApp } from 'firebase/app';
import {
  getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged,
  signInWithPopup, GoogleAuthProvider, applyActionCode, sendPasswordResetEmail,
  confirmPasswordReset, verifyPasswordResetCode, updateProfile, sendEmailVerification, signOut,
} from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyB4hSKLFWNuYREXbepOWtHKdTSjr4ZY_0A',
  authDomain: 'breathiq.firebaseapp.com',
  databaseURL: 'https://breathiq.firebaseio.com',
  projectId: 'breathiq',
  storageBucket: 'breathiq.appspot.com',
  messagingSenderId: '100820185262',
  appId: '1:100820185262:web:e614a5752d7c007ca93d3f',
  measurementId: 'G-KVJ91JPVHM',
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

class Auth {
  signedInUser = () => auth.currentUser;

  signInWithEmailAndPassword = (
    email, password,
  ) => signInWithEmailAndPassword(auth, email, password)

  createUserWithEmailAndPassword = ({
    name, email, password,
  }) => createUserWithEmailAndPassword(auth, email, password)
    .then(async (credential) => {
      await updateProfile(credential.user, { displayName: name });
      if (!credential.user.emailVerified) {
        await sendEmailVerification(credential.user);
      }
      return this.signInWithEmailAndPassword(email, password);
    });

  signOut = () => signOut(auth);

  onAuthStateChanged = (observer) => onAuthStateChanged(auth, observer);

  signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  };

  setToken = async () => {
    const result = await auth.currentUser.getIdTokenResult();

    localStorage.setItem('token', result.token);
    localStorage.setItem('authTime', result.authTime);
    localStorage.setItem('expirationTime', result.expirationTime);

    return result;
  }

  applyActionCode = (code) => applyActionCode(auth, code);

  sendPasswordResetEmail = (email) => sendPasswordResetEmail(auth, email)

  confirmPasswordReset = (code, newPassword) => confirmPasswordReset(auth, code, newPassword)

  verifyPasswordResetCode = (code) => verifyPasswordResetCode(auth, code)

  clearToken = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('authTime');
    localStorage.removeItem('expirationTime');
  }
}

export default Auth;
