import { Layout, Menu } from 'antd';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { SECONDARY_COLOR } from '../../../constants';
import routes from './routes';

const AppDrawer = () => {
  const history = useHistory();
  const location = useLocation();
  const [current, setCurrent] = React.useState(location.pathname);

  const handleClick = (e) => {
    setCurrent(e.key);
    history.push(e.key);
  };

  return (
    <Layout.Sider collapsible style={{ background: SECONDARY_COLOR }}>
      <div style={{ height: '68px' }} />
      <Menu
        theme="dark"
        onClick={handleClick}
        selectedKeys={[`/${current.split('/')[1]}`]}
        mode="inline"
        style={{ background: SECONDARY_COLOR }}
      >
        {routes.map((r) => (
          <Menu.Item key={r.path} icon={r.icon}>{r.name}</Menu.Item>
        ))}
      </Menu>
    </Layout.Sider>
  );
};

export default AppDrawer;
