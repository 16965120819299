import countriesList from 'countries-list';

const countries = Object.keys(countriesList.countries).map((code) => ({
  name: countriesList.countries[code].name,
  emoji: countriesList.countries[code].emoji,
  code,
}));

countries.sort((a, b) => a.name.localeCompare(b.name));

export default countries;
