/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Redirect, useHistory } from 'react-router-dom';
import {
  Button,Card, Col, Form, Input, message, Row, Space, Spin, Typography,
} from 'antd';
import { MailOutlined } from '@ant-design/icons';

import { AuthContext } from '../AuthContextProvider';
import { selectors as appSelectors } from '../../App/slice';
import { SECONDARY_COLOR } from '../../../constants';

const ForgotPasswordPage = () => {
  const user = useSelector(appSelectors.makeSelectUser());
  const loading = useSelector(appSelectors.makeSelectLoading());
  const { currentUser } = React.useContext(AuthContext);
  const history = useHistory();
  const { sendPasswordResetEmail } = React.useContext(AuthContext);
  const [submitting, setSubmitting] = React.useState(false);

  const onSignInClickHandler = () => {
    history.push('/login');
  };

  const onSubmitHandler = async ({ email }) => {
    setSubmitting(true);
    await sendPasswordResetEmail(email);
    message.success('Reset instructions sent');
    setSubmitting(false);
    history.push('/login');
  };

  if (loading) {
    return (
      <Helmet>
        <title>Forgot Password</title>
        <meta name="description" content="Forgot Password" />
      </Helmet>
    );
  }

  if (currentUser && !user) {
    return <Redirect to="/onboard" />;
  }

  if (currentUser && user) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <Helmet>
        <title>Forgot Password</title>
        <meta name="description" content="Forgot Password" />
      </Helmet>
      <Row justify="center" align="middle" style={{ height: '100vh', background: SECONDARY_COLOR }}>
        <Col xxl={5} xl={6} lg={8} md={12} sm={12} xs={20}>
          <Spin spinning={submitting}>
            <Card>
              <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <Typography>
                  <Typography.Title>Forgot Password</Typography.Title>
                  <Typography.Text>
                    Enter your email and instructions will be sent to you!
                  </Typography.Text>
                </Typography>
                <Form
                  name="forgot_password"
                  onFinish={onSubmitHandler}
                  size="large"
                >
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: 'Required' },
                      { validateTrigger: 'onBlur', type: 'email', message: 'Input is not a valid email address' },
                    ]}
                  >
                    <Input prefix={<MailOutlined />} placeholder="Email" />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                      Send reset instructions
                    </Button>
                  </Form.Item>
                </Form>
              </Space>
              <Typography style={{ textAlign: 'center' }}>
                <Typography.Link onClick={onSignInClickHandler}>Sign in</Typography.Link>
              </Typography>
            </Card>
          </Spin>
        </Col>
      </Row>
    </div>
  );
};

ForgotPasswordPage.propTypes = {};

export default ForgotPasswordPage;
