export const CATEGORY_GENDER = 'GENDER';
export const CATEGORY_FITNESS_LEVEL = 'FITNESS_LEVEL';
export const CATEGORY_BODY_TYPE = 'BODY_TYPE';
export const CATEGORY_MEDICAL_CONDITION = 'MEDICAL_CONDITION';
export const CATEGORY_UNIT = 'UNIT';
export const CATEGORY_ACTIVITY = 'ACTIVITY';
export const CATEGORY_STRESS_LEVEL = 'STRESS_LEVEL';
export const CATEGORY_ENERGY = 'ENERGY';
export const CATEGORY_STATE = 'STATE';

export const PAGE_SIZE = 50;

export const PRIMARY_COLOR = '#1392d0';
export const SECONDARY_COLOR = '#1392d0';
export const ACCENT_COLOR = '#f15a2c';
export const TAG_STYLE = {
  color: '#f15a2c',
  background: '#fff6f0',
  borderColor: '#ffc5a8',
};
