import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'antd';
import UserAvatar from '../UserAvatar';

const UserListItem = ({
  photoUrl, name, email, extra, actions,
}) => (
  <List.Item key={email} extra={extra} actions={actions}>
    <List.Item.Meta
      avatar={<UserAvatar photoUrl={photoUrl} name={name} email={email} />}
      title={name || email}
      description={email}
    />
  </List.Item>
);

UserListItem.defaultProps = {
  photoUrl: undefined,
  extra: undefined,
  actions: undefined,
  name: null,
};

UserListItem.propTypes = {
  photoUrl: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string.isRequired,
  extra: PropTypes.any,
  actions: PropTypes.array,
};

export default UserListItem;
