import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Spin, Card, Button, Col, Radio,
} from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { AreaChartOutlined, BarChartOutlined } from '@ant-design/icons';
import Chart from '../../Common/Chart';
import { selectors } from '../../App/slice';
import KeySelect from '../KeySelect';

const TrendSelector = (props) => {
  const {
    data, defaultKeys, brushSeries, onSaveChart,
  } = props;

  const [keys, setKeys] = React.useState(defaultKeys || []);
  const [chartType, setChartType] = React.useState('area');
  const { trendsKeys } = useSelector(selectors.makeSelectKeys());
  const children = _.flatMap(trendsKeys.map((e) => e.children));
  const labels = children.reduce((obj, child) => {
    // eslint-disable-next-line no-param-reassign
    obj[child.value] = child.label;
    return obj;
  }, {});
  const units = children.reduce((obj, child) => {
    // eslint-disable-next-line no-param-reassign
    obj[child.value] = child.unit;
    return obj;
  }, {});
  const divs = children.reduce((obj, child) => {
    // eslint-disable-next-line no-param-reassign
    obj[child.value] = child.div;
    return obj;
  }, {});

  const [min, max] = useSelector(selectors.makeSelectExtent());

  const yFormatter = (key, v) => {
    const fractionDigits = 1;
    const unit = units[key] || '';
    return `${parseFloat(v).toFixed(fractionDigits)} ${unit}`;
  };

  React.useEffect(() => {
    setKeys(defaultKeys || []);
  }, [defaultKeys]);

  return (
    <>
      <Row justify="space-between" gutter={16}>
        <Col xs={19}>
          <KeySelect onOk={(_keys) => { setKeys(_keys); }} values={keys} />
        </Col>
        <Col>
          <Radio.Group value={chartType} onChange={(e) => { setChartType(e.target.value); }}>
            <Radio.Button value="area"><AreaChartOutlined /></Radio.Button>
            <Radio.Button value="bar"><BarChartOutlined /></Radio.Button>
          </Radio.Group>
        </Col>
        <Col>
          <Button block type="primary" disabled={keys.length === 0} onClick={() => { onSaveChart(keys); }}>Save chart</Button>
        </Col>
      </Row>

      {keys.length > 0 ? (
        <Spin spinning={!data.length}>
          <Card style={{ margin: '8px 0px' }}>
            <Chart
              id={keys.join(',')}
              series={keys.map((key) => ({
                name: labels[key],
                key,
                data: data.map((e) => [moment(e.time).valueOf(), e[key] / (divs[key] || 1)])
                  .filter((e) => e[1] !== null),
              }))}
              yFormatter={yFormatter}
        // xaxis={xaxis}
              brushSeries={brushSeries}
              min={min}
              max={max}
              markers={{
                size: 4,
                hover: {
                  size: 6,
                },
              }}
              animations={{ enabled: false }}
        // events={events}
              multiple
              type={chartType}
            />
          </Card>
        </Spin>
      ) : null}

      {/* <Chart
        id={(key1 || '') + (key2 || '')}
        series={series}
        yFormatter={yFormatter}
          // group="trends"
        opacity={0.4}
        min={min}
        max={max}
        markers={{
          size: 4,
          hover: {
            size: 6,
          },
        }}
        animations={{ enabled: false }}
      /> */}
    </>
  );
};

TrendSelector.defaultProps = {
  defaultKeys: [],
};

TrendSelector.propTypes = {
  defaultKeys: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.array.isRequired,
  brushSeries: PropTypes.array.isRequired,
  onSaveChart: PropTypes.func.isRequired,
};

export default TrendSelector;
