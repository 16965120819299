import { getRequest } from '../services/request';

const fetchTags = async (user) => {
  const params = new URLSearchParams({
    filter: JSON.stringify({ userId: user.id }),
    order: 'ASC',
    orderBy: 'name',
  });
  const { data } = await getRequest(`/tags?${params}`);
  return data;
};

export default { fetchTags };
