/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { postRequest } from '../../services/request';

const initialState = {
  loading: false,
  error: false,
};

const insertUser = createAsyncThunk(
  'onboard/insertUser',
  async (user) => {
    const inserted = await postRequest('/users', user);
    return inserted;
  },
);

const slice = createSlice({
  name: 'onboard',
  initialState,
  reducers: {

  },
  extraReducers: {
    [insertUser.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
    },
    [insertUser.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [insertUser.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
  },
});

const substate = (state) => state.OnboardPage || initialState;

const makeSelectLoading = () => createSelector(substate, (sub) => sub.loading);
const makeSelectError = () => createSelector(substate, (sub) => sub.error);

const selectors = { makeSelectLoading, makeSelectError };

const { actions, reducer } = slice;

export { actions, selectors, insertUser };
export default reducer;
