/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Form, Input, message, Space, Spin,
} from 'antd';

import { AuthContext } from '../AuthContextProvider';

const ChangePasswordDialog = ({ open, onClose }) => {
  const [submitting, setSubmitting] = React.useState(false);
  const { currentUser, signInWithEmailAndPassword } = React.useContext(AuthContext);
  const [form] = Form.useForm();

  const onSubmitHandler = async () => {
    try {
      const values = await form.validateFields();
      form.resetFields();
      setSubmitting(true);
      try {
        await currentUser.updatePassword(values.newPassword);
        message.success('Password change successful');
      } catch (err) {
        if (err.code === 'auth/requires-recent-login') {
          try {
            await signInWithEmailAndPassword(currentUser.email, values.password);
            await currentUser.updatePassword(values.newPassword);
            message.success('Password change successful');
          } catch (error) {
            message.error(err.message);
          }
        } else {
          message.error(err.message);
        }
      } finally {
        setSubmitting(false);
        onClose();
      }
      // eslint-disable-next-line no-empty
    } catch (err) {

    }
  };

  return (
    <Modal title="Change Password" visible={open} onCancel={onClose} okText="Change password" onOk={onSubmitHandler}>
      <Spin spinning={submitting}>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Form
            form={form}
            name="change_password"
            size="large"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
          >
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Required' }]}
              label="Current password"
            >
              <Input type="password" placeholder="Enter your current password" />
            </Form.Item>
            <Form.Item
              name="newPassword"
              rules={[
                { required: true, message: 'Required' },
                { validateTrigger: 'onBlur', min: 6, message: 'Password should be at least 6 characters long' },
              ]}
              label="New password"
            >
              <Input type="password" placeholder="Enter your new password" />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Confirm password"
              rules={[
                { required: true, message: 'Required' },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Passwords do not match!'));
                  },
                }),
              ]}
            >
              <Input type="password" placeholder="Confirm new password" />
            </Form.Item>
          </Form>
        </Space>
      </Spin>
    </Modal>
  );
};

ChangePasswordDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ChangePasswordDialog;
