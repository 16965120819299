const cache = new Map();

async function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  const xTotalCount = response.headers.get('X-Total-Count');
  const { data, code, error } = await response.json();
  if (code >= 200 && code < 300) {
    return xTotalCount ? { data, xTotalCount } : data;
  }

  const err = new Error(error);
  err.code = code;
  err.text = error;
  throw err;
}

async function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const text = await response.text();
  const error = new Error(text);
  error.code = response.status;
  error.text = text;
  throw error;
}

const baseUrl = process.env.REACT_APP_BASE_URL;

const request = async (method, path, body, shouldCache) => {
  const url = `${baseUrl}${path}`;
  if (shouldCache && cache.has(url)) {
    return cache.get(url);
  }

  const token = localStorage.getItem('token');
  const response = await fetch(url, {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: token && `Bearer ${token}`,
    },
    body: body && JSON.stringify(body),
  })
    .then(checkStatus)
    .then(parseJSON);

  if (shouldCache) {
    cache.set(url, response);
  }

  return response;
};

export const getRequest = (path, shouldCache = false) => request('GET', path, undefined, shouldCache);
export const postRequest = (path, body) => request('POST', path, body);
export const putRequest = (path, body) => request('PUT', path, body);
export const deleteRequest = (path) => request('DELETE', path);
